import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Upload from './pages/Upload'


export default function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Upload} />
            </Switch>
        </BrowserRouter>
    )
}