import React, { useState, useEffect } from 'react'
import api from '../../services/api'
// import Alert from '../../components/Alert';
import { makeStyles } from '@material-ui/core/styles';

import '../../assets/css/style.css'
import './upload.css'

import {
    Container,
    Paper,
    Grid,
    Button,
    FormControl,
    TextField,
    InputLabel,
    Select,
    ListSubheader,
    MenuItem,
    Snackbar
} from '@material-ui/core'

const useStyles = makeStyles({
    paper: {
        padding: 30,
        marginTop: 50
    },
    minwidth: {
        minWidth: 300
    },
    button: {
        background: "#000",
        color: "#fff",
        "&:hover": {
            color: "#000"
        },
    },
    listSubHeaderRoot: {
        backgroundColor: '#3f51b5',
        color: '#ffffff'
    }
})

export default function Upload({ history, match }) {

    const [name, setName] = useState('')
    const [link, setLink] = useState('')
    const [template, setTemplate] = useState('')
    const [zipFile, setZipFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const [hasLink, setHasLink] = useState(false)
    const [message, setMessage] = useState('')
    const [messageOpen, setMessageOpen] = useState(false)
    const classes = useStyles()

    const handleTemplate = (item) => {
        item.search("google") >= 0 ? setHasLink(true) : setHasLink(false)
        setTemplate(item)
    }
        
    const handleSubmit = (e) => {
        e.preventDefault()
        setName('')
        setLink('')
        setTemplate('')

        return uploadFile()

    }

    const handleMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessageOpen(false)
    }

    const handleFile = (file) => {
        file ? setFileName(file.name) : setFileName(``)
        setZipFile(file)
    }

    const handleDownload = async (downloadFile) => {
        const response = await api.get(`/download/${downloadFile}`, { responseType: 'blob' })

        const responseFile = response.data || false

        const url = window.URL.createObjectURL(new Blob([responseFile], { type: 'application/zip' }))

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', downloadFile)
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const uploadFile = async () => {
        try {
            if (template !== '' && zipFile !== null) {
                const bannerData = new FormData()

                bannerData.append('name', name)
                bannerData.append('link', link)
                bannerData.append('template', template)
                bannerData.append('zipFile', zipFile)

                const response = await api.post('/upload', bannerData)
                const bannersCreated = response.data || false

                if (bannersCreated) {
                    setMessage('The banners were created')
                    setMessageOpen(true)

                    handleDownload(bannersCreated)

                    console.log('Banners created')
                } else {
                    console.log(response.data)
                }
            } else {
                console.log('Missing required field')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Container>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h2>Create HTML banners</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.minwidth}>
                            <InputLabel id="demo-simple-select-outlined-label">Template</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={template}
                                onChange={(e) => handleTemplate(e.target.value)}
                                label="Age"
                            >
                                <ListSubheader classes={{root:classes.listSubHeaderRoot}}>Google</ListSubheader>
                                <MenuItem value='google-1-copy'>Google - 1 copy</MenuItem>
                                <MenuItem value='google-2-copies'>Google - 2 copies</MenuItem>
                                <MenuItem value='google-1-copy-no-logo'>Google - 1 copy - No logo layer</MenuItem>
                                <MenuItem value='google-2-copies-no-logo'>Google - 2 copies - No logo layer</MenuItem>
                                <MenuItem value='google-2-copies-slide-vertical'>Google - 2 copies - Slide vertical</MenuItem>
                                <MenuItem value='google-2-copies-slide-vertical-small'>Google - 2 copies - Slide vertical small</MenuItem>
                                <MenuItem value='google-2-copies-slide-horizontal'>Google - 2 copies - Slide horizontal</MenuItem>
                                <ListSubheader classes={{root:classes.listSubHeaderRoot}}>6sense</ListSubheader>
                                <MenuItem value='6sense-1-copy'>6sense - 1 copy</MenuItem>
                                <MenuItem value='6sense-2-copies'>6sense - 2 copies</MenuItem>
                                <MenuItem value='6sense-1-copy-no-logo'>6sense - 1 copy - No logo layer</MenuItem>
                                <MenuItem value='6sense-2-copies-no-logo'>6sense - 2 copies - No logo layer</MenuItem>
                                <MenuItem value='6sense-2-copies-slide-vertical'>6sense - 2 copies - Slide vertical</MenuItem>
                                <MenuItem value='6sense-2-copies-slide-vertical-small'>6sense - 2 copies - Slide vertical small</MenuItem>
                                <MenuItem value='6sense-2-copies-slide-horizontal'>6sense - 2 copies - Slide horizontal</MenuItem>
                                <ListSubheader classes={{root:classes.listSubHeaderRoot}}>Other</ListSubheader>
                                <MenuItem value='1-copy-no-link'>Google - 1 copy - No Link</MenuItem>
                                <MenuItem value='2-copies-no-link'>Google - 2 copies - No Link</MenuItem>
                                <MenuItem value='google-2-copies-slower-animation'>Google - 2 copies - Slower Animation</MenuItem>
                                <MenuItem value='google-3-bg-2-copies-v2'>Google - 3 BG, 2 copies V2</MenuItem>
                                <MenuItem value='6sense-2-bg-2-copies'>6sense - 2 BG, 2 copies</MenuItem>
                                <MenuItem value='6sense-2-bg-1-copy'>6sense - 2 BG, 1 copy</MenuItem>
                                <MenuItem value='6sense-3-bg-2-copies'>6sense - 3 BG, 2 copies</MenuItem>
                                <MenuItem value='6sense-3-bg-1-copy'>6sense - 3 BG, 1 copy</MenuItem>
                                <MenuItem value='here-2-copies'>HERE - 2 copies</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    { hasLink && (
                        <Grid item xs={12}>
                            <TextField
                                label="Link"
                                name="link"
                                id="link"
                                value={link}
                                variant="outlined"
                                className={classes.minwidth}
                                onChange={(e) => setLink(e.target.value)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <label htmlFor="upload-zip">
                            <input
                                style={{ display: "none" }}
                                id="upload-zip"
                                name="upload-zip"
                                type="file"
                                onChange={(e) => handleFile(e.target.files[0])}
                            />

                            <Button color="primary" variant="contained" component="span">
                                Upload file
                            </Button>{" "}
                        </label>
                        <span>{fileName}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            className={classes.button}
                            onClick={handleSubmit}>Create banners
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {/*<Snackbar
                            open={messageOpen}
                            autoHideDuration={6000}
                            onClose={handleMessageClose}
                        >
                             <Alert onClose={handleMessageClose} severity="success">
                                {message}
                            </Alert> 
                        </Snackbar>*/}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}